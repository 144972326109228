
import { GetCategories } from '@/config/actions';
import { Category } from '@/models';
import { useSignalR } from '@quangdao/vue-signalr';
import { onMounted, defineComponent, ref } from 'vue';

export default defineComponent({
	emits: ['selected'],
	setup(_, { emit }) {
		const signalr = useSignalR();
		const categories = ref<Category[]>([]);

		onMounted(async () => {
			categories.value = await signalr.invoke(GetCategories);
		});

		const select = (category: Category) => emit('selected', category);

		return { categories, select };
	}
});
